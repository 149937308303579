<template>
  <v-container fluid grey lighten-3 pa-10>
    <v-row class="justify-center">
      <v-col>
        <v-card class="mx-auto" max-width="450">
          <v-card-title>แก้ไข Bank</v-card-title>
          <v-card-text>
            <p><b>ชื่อ บัญชี</b> คำแนะนำ ควร(ตั้งชื่อให้ตรงกับตอนลิสรายการ จะช่วยกรอกข้อมูลอัตโนมัติ) เช่น กสิกร หนุ่ม, kbank หนุ่ม (ใช้เครื่องหมาย "," แบ่งชื่อบัญชี เพื่อทำการค้นหาหลายชื่อได้)</p>
            <v-form v-model="valid" ref="form" @submit.prevent="submit">
              <v-container>
                <v-row>

                  <v-col cols="12">
                    <v-text-field
                      v-model="item.name"
                      :rules="nameRules"
                      :counter="50"
                      label="ชื่อ ธนาคาร"
                      id="name"
                      maxlength="50"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="item.account_name"
                      :rules="nameRules"
                      :counter="50"
                      label="ชื่อ บัญชี (ตั้งชื่อให้ตรงกับตอนลิสรายการ จะช่วยกรอกข้อมูลอัตโนมัติ)"
                      id="account_name"
                      maxlength="50"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="item.account_no"
                      :rules="nameRules"
                      :counter="50"
                      label="เลขบัญชี"
                      id="account_no"
                      maxlength="50"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="item.start_amount"
                      label="จำนวนเงินสะสม เร่ิมต้น"
                      id="start_amount"
                      type="number"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="item.start_count"
                      label="จำนวนครั้งการโอน"
                      id="start_count"
                      type="number"
                    ></v-text-field>
                  </v-col>


                  <v-col cols="12">
                    <v-text-field
                      v-model="item.remark"
                      label="หมายเหตุ"
                      id="remark"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="item.no"
                      type="number"
                      label="No ลำดับ"
                      id="no"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-file-input
                      label="File input"
                      accept="image/*"
                      prepend-icon="mdi-camera"
                      type="file"
                      @change="onFileSelected"
                    ></v-file-input>

                    <div v-if="isOldImage">
                      <v-img
                        :src="oldImageUrl"
                        max-height="150"
                        max-width="150"
                        class="mt-2"
                      ></v-img>
                      <span>{{ oldImageUrl }}</span>
                    </div>
                    <div v-else>
                      <v-img
                        v-if="imageUrl"
                        :src="imageUrl"
                        max-height="150"
                        max-width="150"
                        class="mt-2"
                      ></v-img>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <v-switch
                      v-model="item.active"
                      label="Active"
                      color="success"
                      hide-details
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-divider class="mt-5 mb-5"></v-divider>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn color="gray" class="mr-5" @click="$router.go(-1)">กลับ</v-btn>
                  <v-btn :disabled="!valid" color="success" type="submit"
                    >
        <v-icon dark class="mr-1">mdi-checkbox-marked-circle</v-icon> บันทึก</v-btn
                  >
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/services/api";
import _ from "lodash";

export default {
  name: "BankCreate",
  data() {
    return {
      item: {
        id: "",
        name: "",
        account_name: "",
        account_no: "",
        remark: "",
        start_amount: "",
        start_count: "",
        image: "",
        no: 0,
        active: 0
      },
      nameRules: [
        v => !!v || "This is required",
        v =>
          (typeof v != "undefined" && v.length <= 50) ||
          "This must be less than 50 characters"
      ],
      imageUrl: null,
      valid: true,
      isOldImage: ''
    };
  },
  methods: {
    async submit() {
      //alert(JSON.stringify(this.item))

      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("name", _.trim(this.item.name));
        formData.append("account_name", _.trim(this.item.account_name));
        formData.append("account_no", _.trim(this.item.account_no));
        formData.append("remark", _.trim(this.item.remark));
        formData.append("start_amount", this.item.start_amount);
        formData.append("start_count", this.item.start_count);
        formData.append("no", this.item.no);
        formData.append("active", this.item.active ? 1 : 0);
        formData.append("id", this.item.id);

        if (this.imageFile != null) {
            formData.append("image", this.imageFile);
        }

        await api.updateBank(formData);
        this.$router.push("/bank");
      }
    },
    onFileSelected(event) {
      const reader = new FileReader();

      reader.onload = event => {
        this.isOldImage = false;
        this.imageUrl = event.target.result; //สำหรับ preview ข้อมูลรูปภาพ data:image/jpeg;base64fdasfdasfdasf .....
      };

      if (event) {
        reader.readAsDataURL(event);
        this.imageFile = event; // สำหรับ upload
      } else {
        //กดปุ่ม x ยกเลิก ที่อัพโหลดภาพ
        this.isOldImage = true;
        this.imageFile = null;
      }
    },
    getImage() {
      if (this.item.image) {
        if (this.imageUrl) {
          return this.imageUrl;
        } else {
          // การเข้าถึง filter แบบ global ที่เราสร้างขึ้นมาในไฟล์ main.js ถ้าเรียกใช้งานตรง template จะเป็น   image | imageUrl *การเรียกใช้งาน filter
          return this.$options.filters.imageUrl(this.oldImage);
        }
      }
    },
    cancel() {
      this.$router.push("/Bank");
    },
    reset() {
      this.imageUrl = "";
      this.item.image = "";
      this.$refs.form.reset();
    }
  },
  async mounted() {
    let result = await api.getBankById(this.$route.params.id);
    if (result.data.image) {
      this.isOldImage = true;
      this.oldImageUrl = this.$options.filters.imageUrl(result.data.image);
    }

    this.item = result.data;
  },
};
</script>

<style></style>
